@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Khmer&display=swap');

* {
  @apply font-khmer;
}

html {
  @apply max-w-full h-full;
}

body,
#root {
  @apply h-full overflow-hidden;
}

#mainNav .active {
  @apply font-semibold text-pink-500;
}

#accordion-group .accordion-items {
  @apply p-0;
}

#accordion-group .accordion-items button {
  @apply px-4 bg-orange-400 rounded-md shadow-sm;
}

#accordion-group .accordion-items button span svg {
  @apply text-black size-6;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }
  .no-print {
    @apply !hidden;
  }

  .print-only {
    @apply !block;
  }
}
